import './App.css';
import Home from "./views/Home";
import React from "react";

function App() {
  return (
    <Home />
  );
}

export default App;
